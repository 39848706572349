'use client';

import { FormEventHandler } from 'react';
import { useForm } from 'react-hook-form';

import { Button, ButtonVariant, Image } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import {
    formatTranslation,
    getTranslation,
    InternationalizationProps,
    useMixpanel,
} from '@lib/machine-parts/storefront/utils';

import { useSubscribeMutation } from '../../hooks/api/subscribe-mutation/useSubscribeMutation';
import { NewsletterSubscribeModal } from '../molecules/modal/NewsletterSubscribeModal';

export function NewsletterSection(params: InternationalizationProps) {
    const t = formatTranslation(getTranslation(params));

    const mixpanel = useMixpanel();
    const { subscribe, reset: resetSubscribe, isSuccess, isLoading, status } = useSubscribeMutation();

    const {
        register,
        formState: { isValid },
        getValues,
        reset,
    } = useForm({ mode: 'onChange' });

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        try {
            mixpanel.track('Newsletter Signup Confirm Button', {
                body: getValues(),
            });
            await subscribe('/api/newsletter/subscribe', getValues());
        } finally {
            reset();
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit}
                id="newsletter"
                className={`w-full flex justify-center items-center bg-brand-brown flex-col`}
            >
                <div className={`relative w-full`}>
                    <Image
                        src={'banner/background-arrows.png'}
                        alt="placeholder"
                        className="absolute w-full h-full object-cover object-bottom"
                    />
                    <div
                        className={`w-full h-96 flex justify-center items-center flex-col gap-8 z-10 relative my-8 px-4 lg:px-0`}
                    >
                        <h1
                            className={`inline-block text-white font-extrabold text-3xl lg:text-6xl text-center w-full max-w-2xl`}
                        >
                            {t('news-signup-join-newsletter', { defaultValue: 'Join our community newsletter' })}
                        </h1>

                        <div className="flex flex-col sm:flex-row gap-4 items-stretch text-white w-full max-w-5xl justify-between">
                            <div id="form-first-name-input">
                                <label htmlFor="first_name" className="text-white">
                                    {t('news-signup-first-name', { defaultValue: 'First Name' })}
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className="w-full px-5 py-3 border border-gray-300 focus:ring-brand-black focus:border-brand-black shadow-sm bg-transparent placeholder-white focus:ring-1 sm:max-w-2xs"
                                    placeholder={t('news-signup-first-name-placeholder', {
                                        defaultValue: 'First name',
                                    })}
                                    {...register('firstName', { required: true })}
                                />
                            </div>
                            <div id="form-last-name-input">
                                <label htmlFor="last_name" className="">
                                    {t('news-signup-last-name', { defaultValue: 'Last Name' })}
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className="w-full px-5 py-3 border border-gray-300 focus:ring-brand-black focus:border-brand-black shadow-sm bg-transparent placeholder-white focus:ring-1 sm:max-w-2xs"
                                    placeholder={t('news-signup-last-name-placeholder', {
                                        defaultValue: 'Last name',
                                    })}
                                    {...register('lastName', { required: true })}
                                />
                            </div>
                            <div className={`flex-grow`}>
                                <label htmlFor="email">
                                    {t('news-signup-email', { defaultValue: 'Email address' })}
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    autoComplete="email"
                                    className="w-full px-5 py-3 border border-gray-300 focus:ring-brand-black focus:border-brand-black shadow-sm bg-transparent placeholder-white focus:ring-1"
                                    placeholder={t('news-signup-email-placeholder', { defaultValue: 'Email' })}
                                    {...register('email', { required: true })}
                                />
                            </div>
                        </div>
                        <div className="text-sm text-white ">
                            {t('news-signup-gdpr-notice', {
                                defaultValue: 'We care about the protection of your data. Read our ',
                            })}
                            <div className={`inline-block`}>
                                <Link
                                    href="/privacy-and-cookie-policy"
                                    variant={ButtonVariant.None}
                                    className={`inline-block underline underline-offset-4`}
                                    mixpanel={{ eventName: `Newsletter Signup Go To Privacy And Cookie Policy` }}
                                >
                                    {t('news-signup-privacy-policy-link', { defaultValue: 'privacy policy' })}
                                </Link>
                            </div>
                            .
                        </div>
                    </div>
                </div>
                <div className={`flex justify-end items-start w-full max-w-5xl py-8 h-48 px-4 lg:px-0`}>
                    <div className={`flex justify-center gap-8 items-center`}>
                        <Button disabled={!isValid} className={`bg-brand-black`} type="submit" icon>
                            {t('news-signup-confirm-button', { defaultValue: 'confirm' })}
                        </Button>
                    </div>
                </div>
            </form>
            <NewsletterSubscribeModal
                isSuccess={isSuccess}
                isLoading={isLoading}
                isOpen={status !== 'IDLE'}
                onClose={resetSubscribe}
            />
        </>
    );
}
