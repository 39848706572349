'use client';

import { PromotionalBannerSanity } from '@lib/machine-parts/storefront/data-access/server';
import { ButtonVariant, Image, ImagePreview } from '@lib/machine-parts/storefront/ui';
import { formatTranslation, useTailwindBreakpoint } from '@lib/machine-parts/storefront/utils';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';

export function PromotionalBanner(promotionalBannerSanity: PromotionalBannerSanity) {
    const t = formatTranslation(promotionalBannerSanity);

    const useLargeImage = useTailwindBreakpoint('lg', true);
    const useMediumImage = useTailwindBreakpoint('md', true);

    const useSingleImage = !promotionalBannerSanity.image_desktop && !promotionalBannerSanity.image_mobile;

    const useNoImage = useSingleImage && !promotionalBannerSanity.image_tablet;

    return (
        <div className="p-4">
            <div className={'relative w-full bg-black'}>
                {!useNoImage && !useLargeImage && !useMediumImage && !useSingleImage && (
                    <Image
                        src={t('image_mobile.url', { allowUndefined: true })}
                        alt={t('image_mobile.altText', { allowUndefined: true })}
                        className="absolute w-full h-full object-cover object-bottom"
                        blurDataURL={ImagePreview.HOMEPAGE_IMAGE}
                    />
                )}
                {!useNoImage && (useMediumImage || useSingleImage) && (
                    <Image
                        src={t('image_tablet.url', { allowUndefined: true })}
                        alt={t('image_tablet.altText', { allowUndefined: true })}
                        className="absolute w-full h-full object-cover object-bottom"
                        blurDataURL={ImagePreview.HOMEPAGE_IMAGE}
                    />
                )}
                {!useNoImage && useLargeImage && !useSingleImage && (
                    <Image
                        src={t('image_desktop.url', { allowUndefined: true })}
                        alt={t('image_desktop.altText', { allowUndefined: true })}
                        className="absolute w-full h-full object-cover object-bottom"
                        blurDataURL={ImagePreview.HOMEPAGE_IMAGE}
                    />
                )}
                <div className="flex flex-col gap-4 w-full p-8">
                    <h1 className="relative z-1 text-black sm:text-5xl xl:text-6xl max-w-lg">{t('title')}</h1>
                    <p className="relative z-1 text-black max-w-lg">{t('description', { allowUndefined: true })}</p>
                    <Link
                        className="relative z-1 w-fit"
                        variant={ButtonVariant.Primary}
                        border
                        href={t('button.url')}
                        mixpanel={{ eventName: 'Promotion Banner - Clicked' }}
                        icon
                    >
                        {t('button.text')}
                    </Link>
                </div>
            </div>
        </div>
    );
}
