import { Hit, SearchResponse } from '@algolia/client-search';
import { ProductSearchResult, SupportedLanguage } from '@lib/machine-parts/storefront/utils';

import { ProductSearchResults } from '../ProductModel';
import { ExternalProductSearchResults, ProductSearchResultsMapper } from '../ProductSearchResultsMapper';
import { AlgoliaProduct } from './AlgoliaProductModel';
import { OPTION_ALL } from './AlgoliaProductFilterMapper';

export type AlgoliaProductSearchResults = {
    searchResponse: SearchResponse<AlgoliaProduct>;
};

export class AlgoliaProductSearchResultsMapper implements ProductSearchResultsMapper {
    map = (response: ExternalProductSearchResults, language: SupportedLanguage): ProductSearchResults => {
        const currentPage = response.searchResponse.page + 1;
        const numberOfPages = response.searchResponse.nbPages;
        const hasNextPage = currentPage < numberOfPages;
        const hasPreviousPage = response.searchResponse.page > 0;

        return {
            products: response.searchResponse.hits.map((hit) => this.mapToProductSearchResult(hit, language)),
            pagination: {
                currentPage,
                numberOfPages,
                hasNextPage,
                hasPreviousPage,
            },
        };
    };

    private mapToProductSearchResult = (
        algoliaProduct: Hit<AlgoliaProduct>,
        language: SupportedLanguage,
    ): ProductSearchResult => {
        return {
            compatibility: {
                brands: algoliaProduct.compatibility?.brands ?? [OPTION_ALL],
                models: algoliaProduct.compatibility?.models ?? [OPTION_ALL],
                modelYears: algoliaProduct.compatibility?.modelYears ?? [OPTION_ALL],
            },
            handle: algoliaProduct.sku,
            isAvailable: algoliaProduct.stock?.isAvailable ?? false,
            title: algoliaProduct.translations?.[language]?.title ?? algoliaProduct.translations?.en?.title ?? '',
            vendor: algoliaProduct.vendor,
            id: algoliaProduct.objectID,
            sku: algoliaProduct.sku,
            featuredImage: {
                src: algoliaProduct.featuredImage,
                alt: algoliaProduct.translations?.[language]?.title ?? algoliaProduct.translations?.en?.title ?? '',
            },
        };
    };
}
