'use client';

import { useQuery } from '@tanstack/react-query';
import { useSearchContext } from '../../providers/SearchProvider';

export const useAutocomplete = (keywords: string) => {
    const { search } = useSearchContext();

    return useQuery(
        ['autocomplete', keywords],
        () => {
            return search.autocomplete(keywords);
        },
        { staleTime: Infinity, keepPreviousData: true, enabled: !!keywords },
    );
};
