const ERRORS = {
    InvalidParam: `Added invalid param. Param does not contain the right prefix for the param name.`,
    InvalidPagination: 'Pagination string is not of expected length 4',
    InvalidNewsletterSubscribePayload: 'Newsletter subscribe payload does not contain all necessary parameters.',
    InvalidNewsLetterSubscribeHttpMethod: 'Newsletter subscribe http method must be set to "POST"',
    InvalidBackInStockSubscribePayload: 'BackInStock subscribe payload does not contain all necessary parameters.',
    InvalidBackInStockSubscribeHttpMethod: 'BackInStock subscribe http method must be set to "POST"',
    InvalidMailContactPayload: 'Contact mail payload does not contain all necessary parameters.',
    InvalidMailContactHttpMethod: 'Contact mail http method must be set to "POST"',
    InvalidMailProductSuggestionPayload: 'Product suggestion mail payload does not contain all necessary parameters.',
    InvalidMailProductSuggestionHttpMethod: 'Product suggestion mail http method must be set to "POST"',
    InvalidMailMachineBrandPayload: 'Machine brand mail payload does not contain all necessary parameters.',
    InvalidMailMachineBrandHttpMethod: 'Machine brand mail http method must be set to "POST"',
    FileIsNull: 'Provided file is null',
    InvalidPayload: 'Provided payload is invalid',
    InvalidHTTPMethod: 'Provided HTTP method is invalid',
} as const;

export class MachinePartsError extends Error {
    constructor(code: keyof typeof ERRORS) {
        super(code + '. ' + ERRORS[code]);
    }
}
