'use client';

import { createContext, ReactNode, useContext } from 'react';
import { createProductSearchService, CreateSearchServiceOptions } from '../external/search/searchServiceFactory';
import { ProductSearchService } from '../external/search/ProductSearchService';

type SearchProviderProps = {
    children: ReactNode;
    configuration: CreateSearchServiceOptions;
};

export const SearchProvider = ({ children, configuration }: SearchProviderProps) => {
    const search = createProductSearchService(configuration);

    return <SearchContext.Provider value={{ search, configuration }}>{children}</SearchContext.Provider>;
};

const SearchContext = createContext<{ search: ProductSearchService; configuration: CreateSearchServiceOptions }>(
    undefined!,
);

export const useSearchContext = () => useContext(SearchContext);
