'use client';
import { GlobeAltIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import { useCallback, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Button, ButtonVariant, ListSelect, ListSelectOption, SelectIcon } from '@lib/machine-parts/storefront/ui';

interface LanguageSelectionPopupProps {
    initialSelectedLanguage: string;
    availableLanguages: ListSelectOption[];

    initialSelectedRegion: string;
    availableRegions: ListSelectOption[];

    onChange: (language: string, region: string) => void;

    content: {
        title: string;
        description?: string;
        languageLabel: string;
        regionLabel: string;
        saveButtonLabel: string;
        cancelButtonLabel: string;
        disclaimer?: string;
    };
}

export function LanguageSelectionPopup({
    initialSelectedLanguage,
    availableLanguages,
    initialSelectedRegion,
    availableRegions,
    onChange,
    content,
}: LanguageSelectionPopupProps) {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(initialSelectedLanguage);
    const [selectedRegion, setSelectedRegion] = useState(initialSelectedRegion);

    const closeModal = useCallback(() => setModalOpen(false), []);
    const saveAndClose = useCallback(() => {
        closeModal();
        onChange(selectedLanguage, selectedRegion);
    }, [closeModal, onChange, selectedLanguage, selectedRegion]);

    return (
        <>
            <Button
                variant={ButtonVariant.None}
                className="flex flex-row items-center gap-2"
                onClick={() => setModalOpen(true)}
                data-testid="language-selection-button"
            >
                <GlobeAltIcon className="h-6 w-6 inline text-gray-700" />
                <span className="uppercase">{selectedLanguage}</span>
                <SelectIcon aria-hidden="true" />
            </Button>

            <Dialog open={modalOpen} onClose={closeModal}>
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 px-7 z-50" />

                <div className="flex flex-col w-full md:max-w-lg gap-4 pt-6 md:my-8 text-left align-middle bg-white shadow-xl fixed bottom-0 left-0 md:bottom-1/2 md:left-1/2 md:-translate-x-1/2 md:translate-y-1/2 z-50">
                    <Dialog.Title
                        as="h3"
                        className={`px-6 text-2xl font-bold font-heading text-brand-black flex space-between items-center`}
                    >
                        <span className="flex-1 uppercase">{content.title}</span>
                        <Button variant={ButtonVariant.None} onClick={closeModal}>
                            <XIcon className="h-6 w-6 text-gray-700" />
                        </Button>
                    </Dialog.Title>
                    {content.description && (
                        <Dialog.Description className="px-6 font-light text-gray-800 opacity-80">
                            {content.description}
                        </Dialog.Description>
                    )}
                    <div className="flex flex-col gap-4 p-6 bg-gray-100">
                        {content.languageLabel}
                        <div data-testid="language-selector">
                            <ListSelect
                                id="language"
                                selected={selectedLanguage}
                                options={availableLanguages}
                                handleChange={setSelectedLanguage}
                                variant={'register'}
                            />
                        </div>
                        {content.regionLabel}
                        <div data-testid="region-selector">
                            <ListSelect
                                id="region"
                                selected={selectedRegion}
                                options={availableRegions}
                                handleChange={setSelectedRegion}
                                variant={'register'}
                            />
                        </div>

                        <div className="flex justify-end flex-col gap-2">
                            <Button className="w-full" onClick={saveAndClose} icon data-testid="language-save-button">
                                {content.saveButtonLabel}
                            </Button>
                            <Button
                                className="w-full border border-brand-brown"
                                variant={ButtonVariant.Secondary}
                                onClick={closeModal}
                            >
                                {content.cancelButtonLabel}
                            </Button>
                        </div>
                        {content.disclaimer && (
                            <div className="flex flex-row gap-4 items-center mt-8 font-light text-gray-800 opacity-50">
                                <InformationCircleIcon className="h-6 w-6 block" />
                                <div>{content.disclaimer}</div>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
}
