import { ExternalProductFilter, ProductFilterMapper } from '../ProductFilterMapper';
import { ProductFilter } from '../ProductModel';

export const OPTION_ALL = 'all';

export type AlgoliaProductFilter = {
    filters: string;
};

export class AlgoliaProductFilterMapper implements ProductFilterMapper {
    map(productFilter: ProductFilter): ExternalProductFilter {
        let categoryString = productFilter?.categories
            ? productFilter.categories.map((categoryFilter) => 'categories:' + categoryFilter).join(' OR ')
            : '';

        if (categoryString.length > 0) {
            categoryString = '(' + categoryString + ')';
        }

        let vendorFilterString = productFilter?.vendors
            ? productFilter.vendors.map((vendorFilter) => 'vendor:' + vendorFilter).join(' OR ')
            : '';

        if (vendorFilterString.length > 0) {
            vendorFilterString = '(' + vendorFilterString + ')';
        }

        const machineStringSegments: string[] = [];

        if (productFilter?.machine?.brand) {
            machineStringSegments.push(
                `(compatibility.brands:'${productFilter.machine?.brand}' OR compatibility.brands: '${OPTION_ALL}')`,
            );
        }

        if (productFilter?.machine?.model) {
            machineStringSegments.push(
                `(compatibility.models:'${productFilter.machine?.model}' OR compatibility.models: '${OPTION_ALL}')`,
            );
        }

        if (productFilter?.machine?.model && productFilter.machine?.year) {
            machineStringSegments.push(
                `(compatibility.modelYears:'${productFilter.machine.model};${productFilter.machine.year}' OR compatibility.modelYears: '${OPTION_ALL}')`,
            );
        }

        const machineString = machineStringSegments.length > 0 ? `${machineStringSegments.join(' AND ')}` : '';

        const stockStringSegments: string[] = [];

        if (productFilter?.stock) {
            if (productFilter?.stock?.isAvailable) stockStringSegments.push('stock.isAvailable:true');
            if (productFilter?.stock?.inStock) stockStringSegments.push('stock.amountInStock > 0');
        }

        const stockString = stockStringSegments.length > 0 ? `${stockStringSegments.join(' AND ')}` : '';

        let featuredImageString = '';

        if (productFilter?.featuredImage) featuredImageString = '_tags:has_image';

        let isDiscounted = '';
        if (productFilter.isDiscounted) isDiscounted = `(discounted:${String(productFilter.isDiscounted)})`;

        const filters =
            [categoryString, vendorFilterString, machineString, stockString, featuredImageString, isDiscounted]
                .filter((filterString) => filterString.length > 0)
                .join(' AND ') ?? '';

        return {
            filters,
        };
    }
}
