import { SearchResponse } from '@algolia/client-search';
import { AlgoliaAutocomplete } from './AlgoliaProductModel';
import { AutocompleteResultsMapper, ExternalAutocompleteSearchResults } from '../AutocompleteResultsMapper';

export type AlgoliaAutocompleteSearchResults = {
    searchResponse: SearchResponse<AlgoliaAutocomplete>;
};

export class AlgoliaAutocompleteResultsMapper implements AutocompleteResultsMapper {
    map = (searchResult: ExternalAutocompleteSearchResults): string[] => {
        return searchResult.searchResponse.hits.map(({ query }) => query);
    };
}
