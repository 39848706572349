'use client';

import { ImageWithFallback } from '@lib/machine-parts/storefront/ui';
import { formatTranslation } from '@lib/machine-parts/storefront/utils';

import { HomepageSanityData } from '../../../external/sanity/pages/getHompageSanityData';
import { PartPictureModalButton } from '../modal/PartPictureModalButton';

interface PartPictureUploadProps {
    sanityData?: HomepageSanityData['part_picture_upload_section'];
}

export function PartPictureUpload({ sanityData }: PartPictureUploadProps) {
    const t = formatTranslation(sanityData);
    return (
        <div className={`flex flex-col-reverse lg:flex-row justify-between items-center m-14 gap-8`}>
            {sanityData?.image_1 && (
                <ImageWithFallback
                    className={`max-w-xl hidden lg:block`}
                    src={sanityData.image_1.url}
                    alt={sanityData.image_1.altText ?? 'part upload image 1'}
                />
            )}
            <div className="flex flex-col gap-8 justify-center items-center md:w-2xl">
                <h1
                    className={`text-center inline-block text-4xl sm:text-5xl xl:text-6xl tracking-tight font-extrabold`}
                >
                    {t('title')}
                </h1>
                {t('description', { allowUndefined: true }) && (
                    <p className="max-w-2xl text-center">{t('description')}</p>
                )}
                <PartPictureModalButton
                    styleType="button"
                    label={t('button')}
                    className={'text-white w-full max-w-lg'}
                />
            </div>
            {sanityData?.image_2 && (
                <ImageWithFallback
                    className={`max-w-xs lg:max-w-xl block`}
                    src={sanityData.image_2.url}
                    alt={sanityData.image_2.altText ?? 'part upload image 2'}
                />
            )}
        </div>
    );
}
