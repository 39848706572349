'use client';

import { Dialog } from '@headlessui/react';
import { CheckCircleIcon, RefreshIcon, XCircleIcon } from '@heroicons/react/outline';
import { BaseModalProps, Button, ButtonVariant } from '@lib/machine-parts/storefront/ui';
import { useTranslation } from '@lib/machine-parts/storefront/utils';

interface NewsletterSubscribeModalProps extends Pick<BaseModalProps, 'isOpen' | 'onClose'> {
    isLoading: boolean;
    isSuccess: boolean;
}

export function NewsletterSubscribeModal({ isOpen, isLoading, isSuccess, onClose }: NewsletterSubscribeModalProps) {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 px-7" />

            <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                {!isLoading ? (
                    <div className="mt-2">
                        {isSuccess ? (
                            <div className={'w-full mt-2 flex justify-center items-center space-y-4 flex-col'}>
                                <CheckCircleIcon className={`h-16 text-brand-green`} />
                                <p>{t('news-signup-success-signup', 'You were successfully signed up!')}</p>
                            </div>
                        ) : (
                            <div className={'w-full mt-2 flex justify-center items-center space-y-4 flex-col'}>
                                <XCircleIcon className={`h-16 text-brand-red`} />
                                <p>
                                    {t(
                                        'news-signup-error-signup',
                                        'Something went wrong signing you up, please try again later',
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={'w-full mt-2 flex justify-center items-center space-y-4 flex-col'}>
                        <RefreshIcon className={`h-16 text-brand-brown animate-spin`} />
                        <p>{t('news-signup-request-sending', 'Sending Request...')}</p>
                    </div>
                )}

                <div className="mt-4 flex justify-end">
                    <Button
                        variant={ButtonVariant.Secondary}
                        onClick={() => onClose()}
                        mixpanel={{
                            eventName: `Close Newsletter Signup Status Modal Button`,
                        }}
                    >
                        {t('news-signup-close-button', 'Close')}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
