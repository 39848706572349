'use client';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

type PostMissingPartUploadRequest = {
    downloadUrl: string;
    email: string;
    remarks?: string;
};

type PostMissingPartUploadResponse = number;

const postMissingPartUpload = (payload: PostMissingPartUploadRequest) =>
    axios.post<PostMissingPartUploadResponse>('/api/mail/missing-part-picture-upload', payload);

export const useMissingPartUploadMutation = () => {
    return useMutation<number, never, PostMissingPartUploadRequest>(['upload-picture'], async (payload) => {
        const response = await postMissingPartUpload(payload);
        return response.data;
    });
};
