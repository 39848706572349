'use client';

import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CheckCircleIcon } from '@heroicons/react/outline';
import { BaseModal, BaseModalProps, Button, FormGroup, TextInput } from '@lib/machine-parts/storefront/ui';
import { uploadMissingPartPicture, useMixpanel, yup, useTranslation } from '@lib/machine-parts/storefront/utils';

import { MachinePartsError } from '../../../constants/errors';
import { useMissingPartUploadMutation } from '../../../hooks/api/mail/useMissingPartUploadMutation';

type PartPictureUploadModalProps = Pick<BaseModalProps, 'isOpen' | 'onClose'>;

export function PartPictureUploadModal({ isOpen, onClose }: PartPictureUploadModalProps) {
    const mixpanel = useMixpanel();
    const [isSent, setIsSent] = useState(false);
    const { t } = useTranslation();

    const { mutateAsync: upload, isLoading } = useMissingPartUploadMutation();

    useEffect(() => {
        if (isOpen === true) mixpanel.track('Picture Upload Modal Opened', { isOpen });
    }, [isOpen, mixpanel]);

    if (isSent)
        return (
            <BaseModal isOpen={isOpen} onClose={onClose} title={t('missing-part-title')}>
                <div className={'w-full flex justify-center items-center gap-4 flex-col'}>
                    <CheckCircleIcon className={`h-16 text-brand-green`} />
                    <p>{t('missing-part-message-sent', 'Message was sent!')}</p>
                </div>
                <Button
                    className="mt-4"
                    onClick={() => onClose(false)}
                    mixpanel={{ eventName: `Send message Button` }}
                    icon
                >
                    {t('missing-part-close-button', 'Close')}
                </Button>
            </BaseModal>
        );

    return (
        <BaseModal isOpen={isOpen} onClose={onClose} title={t('missing-part-title')}>
            <Formik<{
                remarks: string;
                email: string;
                file: null | File;
            }>
                initialValues={{
                    remarks: '',
                    email: '',
                    file: null,
                }}
                validationSchema={getValidationSchema(t)}
                onSubmit={async ({ email, remarks, file }) => {
                    if (!file) throw new MachinePartsError('FileIsNull');
                    const url = await uploadMissingPartPicture(file);
                    await upload({ email, remarks, downloadUrl: url });
                    setIsSent(true);
                    mixpanel.track('Picture Upload Uploaded Picture', { url, email, remarks });
                }}
            >
                {({ errors, touched, setFieldValue, setFieldTouched }) => {
                    return (
                        <Form>
                            <div className="flex flex-col gap-4">
                                <FormGroup
                                    label={t('missing-part-email-label', 'Email')}
                                    errorMessage={touched.email && errors.email ? errors.email : undefined}
                                >
                                    <Field
                                        as={TextInput}
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder={t('missing-part-email-input-placeholder', 'E-mail address')}
                                        aria-describedby="part-description"
                                    />
                                </FormGroup>
                                <FormGroup
                                    label={t('missing-part-remarks-label', 'Any remarks?')}
                                    errorMessage={touched.remarks && errors.remarks ? errors.remarks : undefined}
                                >
                                    <Field
                                        as={TextInput}
                                        type="text"
                                        name="remarks"
                                        id="remarks"
                                        placeholder={t(
                                            'missing-part-description-input-placeholder',
                                            'A short overview of what you are looking for...',
                                        )}
                                        aria-describedby="part-description"
                                    />
                                </FormGroup>
                                <FormGroup
                                    label={t('missing-part-picture', 'The part you’re looking for')}
                                    errorMessage={!!touched.file && (errors.file as string) ? errors.file : undefined}
                                >
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        className="shadow-sm focus:ring-brand-brown focus:border-brand-brown block w-full sm:text-sm border-gray-300 rounded-md"
                                        aria-describedby="part-description"
                                        onChange={(event) => {
                                            event?.currentTarget?.files?.[0]
                                                ? setFieldValue('file', event.currentTarget.files[0])
                                                : setFieldValue('file', null);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched('file', true);
                                        }}
                                    />
                                </FormGroup>

                                <Button
                                    mixpanel={{ eventName: 'Can’t Find My Part Send Message Button' }}
                                    icon
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {t('missing-part-send-button', 'Send message')}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </BaseModal>
    );
}

function getValidationSchema(t: (key: string, defaultTranslation?: string | undefined) => string) {
    return yup.object().shape({
        remarks: yup.string(),
        email: yup
            .string()
            .email(t('form-validation-email-is-required', 'Please provide an email address'))
            .required(t('form-validation-email-is-required', 'Please provide an email address')),

        file: yup
            .mixed<File>()
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .maxFileSize(20_000_000, t('form-validation-file-too-big', 'This file is too big'))
            .required(t('form-validation-file-is-required', 'Please provide a file')),
    });
}
