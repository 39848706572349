'use client';

import { ButtonIcon, ButtonVariant, ImageWithFallback } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { useMixpanel } from '@lib/machine-parts/storefront/utils';

export default function EssentialsCategory({
    categoryTitle,
    imgSrc,
    linkPath,
    trackEvent,
}: {
    categoryTitle: string;
    imgSrc: string;
    linkPath: string;
    trackEvent: { name: string };
}) {
    const mixpanel = useMixpanel();
    return (
        <Link href={linkPath} variant={ButtonVariant.None} className={`!p-0`} mixpanel={{ eventName: trackEvent.name }}>
            <div
                className={`h-[72px] md:h-[88px] px-[16px] md:px-[24px] lg:px-[16px] xl:px-[24px] bg-[#F5F5F5] flex items-center`}
            >
                <ImageWithFallback
                    src={imgSrc}
                    alt="placeholder"
                    className="w-[84px] h-[56px] mr-[24px]"
                    mixpanel={mixpanel}
                    eventInfo={{ location: 'Essentials category' }}
                />

                <span className={'w-full text-[16px] font-semibold leading-[19.2px]'}>{categoryTitle}</span>

                <div className="ml-[20px] flex justify-end">
                    <ButtonIcon className={'fill-brand-brown w-[16px] h-[18px]'} />
                </div>
            </div>
        </Link>
    );
}
