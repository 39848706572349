'use client';

import { useState } from 'react';

import { UspsSection } from '@lib/machine-parts/storefront/data-access';
import { ButtonIcon } from '@lib/machine-parts/storefront/ui';
import { formatTranslation, useMixpanel } from '@lib/machine-parts/storefront/utils';

export function Quotes({ quotes }: { quotes: NonNullable<UspsSection['quotes']> }) {
    const mixpanel = useMixpanel();
    const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(0);

    return (
        <div className={'flex flex-row justify-between items-center gap-4 md:gap-16 xl:gap-20 px-4 md:px-16 xl:px-20'}>
            <button
                onClick={() => {
                    setSelectedQuoteIndex((oldValue) => (oldValue - 1 + quotes.length) % quotes.length);
                    mixpanel.track(`Previous quote button`);
                }}
            >
                <ButtonIcon className={'fill-brand-brown w-4 h-4 md:w-10 md:h-10 rotate-180'} />
            </button>

            <div className={'flex flex-1 h-64 relative'}>
                {quotes.map((quote, quoteIndex) => (
                    <SingleQuote key={quoteIndex} {...quote} selected={quoteIndex === selectedQuoteIndex} />
                ))}
            </div>
            <button
                onClick={() => {
                    setSelectedQuoteIndex((oldValue) => (oldValue + 1) % quotes.length);
                    mixpanel.track(`Next quote button`);
                }}
            >
                <ButtonIcon className={'fill-brand-brown w-4 h-4 md:w-10 md:h-10'} />
            </button>
        </div>
    );
}

function SingleQuote({ selected, ...item }: NonNullable<UspsSection['quotes']>[number] & { selected: boolean }) {
    const t = formatTranslation(item);
    const person = t(`person`, { allowUndefined: true });
    const company = t(`company`, { allowUndefined: true });
    const quote = t(`quote`);

    return (
        <div
            className={`absolute inset-0 ${
                selected ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-500 flex flex-col gap-2 md:gap-4 items-center justify-center`}
        >
            <h2 className={'text-brand-brown md:text-xl text-center'}>
                {person && <span className={'font-bold'}>{person}</span>}
                {person && company && <span className={'font-bold'}>, </span>}
                {company && <span className={''}>{company}</span>}
            </h2>
            <div className={'flex items-center'}>
                <div className={'text-white text-lg md:text-2xl justify-center flex flex-grow'}>
                    <p className={'text-center'}>{quote}</p>
                </div>
            </div>
        </div>
    );
}
