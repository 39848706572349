'use client';

import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Input, SearchIcon } from '@lib/machine-parts/storefront/ui';
import {
    MixpanelEventProps,
    SearchParam,
    useMixpanel,
    useTranslation,
    useUrlParams,
} from '@lib/machine-parts/storefront/utils';

import { useAutocomplete } from '../../hooks/api/useAutocomplete';
import { useDebounce } from '../../hooks/utils/useDebounce';

const DEFAULT_CLASSES = 'w-full text-base font-normal text-black pl-14 focus:ring-brand-brown';

const VARIANT_CLASSES = {
    indexSearch: 'border-2 border-brand-brown placeholder-neutral-400 h-[50px]',
    headerSearch: 'border-black placeholder-#A8A8A8 focus:shadow-outline focus:border-brand-brown h-[40px]',
} as const;

interface SearchBarProps {
    variant: keyof typeof VARIANT_CLASSES;
    className?: string;
    searchIconStroke?: string;
    trackEvent?: MixpanelEventProps;
}

export function SearchBar({
    variant,
    className,
    searchIconStroke,
    trackEvent = { eventName: 'Search Bar' },
}: SearchBarProps) {
    const mixpanel = useMixpanel();
    const { t } = useTranslation();
    const { buildUrl, searchParams, pathname } = useUrlParams();
    const [keywords, setKeywords] = useState(searchParams?.get(SearchParam.SEARCH) ?? '');
    const { push: navigate } = useRouter();
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const debouncedKeywords = useDebounce(keywords);

    useEffect(() => {
        if (!keywords) setKeywords(searchParams?.get(SearchParam.SEARCH) ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const submitKeywords = useCallback(
        (keywords: string) => {
            navigate(buildUrl({ path: '/categories/all-products', params: { search: keywords } }).url);
        },
        [buildUrl, navigate],
    );

    const handleSearch = () => {
        submitKeywords(keywords);
        inputRef.current?.blur();

        keywords &&
            trackEvent.eventName &&
            mixpanel.track(trackEvent.eventName, {
                search_input: keywords,
                page_of_search: pathname,
                index_or_header: variant,
                ...(trackEvent.properties ?? {}),
            });
    };

    const { data } = useAutocomplete(debouncedKeywords);

    const classes = `${DEFAULT_CLASSES} ${VARIANT_CLASSES[variant]} ${className}`;

    const terms = data?.filter((term) => term !== keywords);

    return (
        <div className="relative w-full flex h-auto bg-white">
            {terms && isFocused && (
                <div className={'absolute top-full bg-white shadow w-full mt-1 rounded-lg z-10'}>
                    {terms.map((term, i) => {
                        return (
                            <button
                                key={term}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                }}
                                onClick={() => {
                                    submitKeywords(term);
                                    inputRef.current?.blur();
                                }}
                                className={`py-2 px-4 w-full text-left border-t hover:bg-gray-50 cursor-pointer ${
                                    i > 0 ? 'border-gray-50' : 'border-transparent'
                                }`}
                            >
                                {term}
                            </button>
                        );
                    })}
                </div>
            )}
            <button onClick={handleSearch} className={'absolute w-5 h-5 ml-5 top-1/2 transform -translate-y-1/2'}>
                <SearchIcon stroke={searchIconStroke ?? 'rgba(49, 49, 49, 0.7)'} />
            </button>
            <Input
                id={'searchBar'}
                data-testid={'searchBar'}
                value={keywords ?? ''}
                onChange={(e) => setKeywords(e.target.value)}
                onEnter={handleSearch}
                className={classes}
                placeholder={t('product-search-input-placeholder', 'item no. - model no. - product name')}
                autocomplete={'off'}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                ref={inputRef}
            />
        </div>
    );
}
