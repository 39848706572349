import {
    AlgoliaProductSearchService,
    AlgoliaProductSearchServiceParameters,
} from './algolia/AlgoliaProductSearchService';
import { ProductSearchService } from './ProductSearchService';

export type CreateSearchServiceOptions =
    | ({
          source: 'ALGOLIA';
      } & AlgoliaProductSearchServiceParameters)
    | {
          source: 'SHOPIFY';
      };

export const createProductSearchService = (options: CreateSearchServiceOptions): ProductSearchService => {
    switch (options.source) {
        case 'ALGOLIA':
            return new AlgoliaProductSearchService(options);
        case 'SHOPIFY':
            throw new Error('Not yet implemented');
    }
};
