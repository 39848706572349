'use client';

import { ButtonHTMLAttributes, useState } from 'react';

import { Button, CameraIcon } from '@lib/machine-parts/storefront/ui';

import { PartPictureUploadModal } from './PartPictureUploadModal';

type PartPictureModalButtonProps = {
    label: string;
    className?: string;
    styleType?: 'link' | 'button';
};

export function PartPictureModalButton({ label, className = '', styleType = 'link' }: PartPictureModalButtonProps) {
    const [isPartPictureUploadOpen, setIsPartPictureUploadOpen] = useState(false);

    return (
        <>
            <ButtonElement
                styleType={styleType}
                className={`text-left flex flex-row underline gap-3 items-center text-sm sm:text-base ${className}`}
                onClick={() => {
                    setIsPartPictureUploadOpen(true);
                }}
            >
                <CameraIcon /> {label}
            </ButtonElement>
            {isPartPictureUploadOpen && (
                <PartPictureUploadModal
                    isOpen={isPartPictureUploadOpen}
                    onClose={() => setIsPartPictureUploadOpen(false)}
                />
            )}
        </>
    );
}

function ButtonElement({ styleType, ...props }: { styleType?: 'link' | 'button' } & ButtonHTMLAttributes<HTMLElement>) {
    if (styleType === 'button') {
        return <Button {...props} />;
    }
    return <button {...props} />;
}
