import { ProductSorting } from '../ProductModel';

export interface Stock {
    isAvailable: boolean;
    availableInMarketGroups: string[];
    amountSold: number;
    amountInStock: number;
}

export interface Prices {
    [key: string]: number;
}

export interface Translation {
    title: string;
    description: string;
}

export interface Translations {
    [key: string]: Translation;
}

export interface Compatibility {
    brands: string[];
    models: string[];
    modelYears: string[];
}

export type Metadata = Record<string, string>;

export interface AlgoliaProduct {
    objectID: string;
    sku: string;
    barcode: string;
    featuredImage: string;
    vendor: string;
    stock: Stock;
    prices: Prices;
    languages: string[];
    translations: Translations;
    categories: string[];
    kits: string[];
    compatibility?: Compatibility;
    metadata: Metadata;
    handle: string;
}

export interface AlgoliaAutocomplete {
    objectID: string;
    query: string;
    popularity: number;
    nb_words: number;
    products: { exact_nb_hits: number; facets: { exact_matches: unknown; analytics: unknown } };
}

export type SortingIndicesStructure = Record<ProductSorting, string>;

export const SORTING_INDICES: SortingIndicesStructure = {
    relevance: 'products',
    'best-selling': 'products',
    'price-ascending': 'products_price_asc',
    'price-descending': 'products_price_desc',
    'alphabetically-ascending': 'products_title_asc',
    'alphabetically-descending': 'products_title_desc',
} as const;
